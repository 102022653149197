<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Elenco ordini </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Elenco ordini</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BCard } from "bootstrap-vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
  },
};
</script>
